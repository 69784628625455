<template>

<div class="page-wrapper">
    <b-card no-body footer-tag="footer">
        <b-tabs card>
            <b-tab title="Invoice">
                <b-form>
                    <span v-if="error" class="error">{{ error }}</span>
                        <inline-form-row label="Invoice Number" disabled v-model="invoice.invoice_number" helpText="This is auto-gererated"/>
                        <inline-form-row label="Invoice Date" _type="b-form-datepicker" v-model="invoice.invoiced" :disabled="!(this.isNew || this.isEditable)" :errors="errors['invoiced']" helpText="Date the invoice ends (generally, the last invoiced day of the month)" />
                        <inline-form-row label="Client" _type="b-form-select" :options="clients" :disabled="!this.isNew" v-model="selected_client" :errors="errors['project']" @change="changeClient()" helpText="Select a client" />
                        <inline-form-row label="Project" _type="b-form-select" :options="projects[selected_client]" :disabled="!this.isNew" v-model="invoice.project" v-if="selected_client" helpText="Select an active Project" />
                        <inline-form-row label="Vendor Number" disabled v-model="invoice.project.client.vendor_number" v-if="invoice.project" helpText="Vendor number (configured in client section)" />
                        <inline-form-row label="PO Number" disabled v-model="invoice.project.purchase_order" v-if="invoice.project" helpText="Purchase Order (configured in the project section)" />
                        <BMSCurrencySelector label="Currency" v-model="invoice.currency" :disabled="!this.isNew || defaultCurrency!== null" :errors="errors['currency']" helpText="" />
                        <inline-form-row label="Description" _type="b-form-textarea" v-model="invoice.description" :errors="errors['description']"  helpText="Description that will appear as additional details on the invoice" />
                </b-form>
            </b-tab>
            <b-tab title="Items" :disabled="!invoice.project">
                <div class="text-right" v-show="isEditable">
                    <b-button @click="addItem()" class="m-1">Add item</b-button>
                    <b-button v-b-modal.timesheet-modal class="m-1">Add timesheeet</b-button>
                </div>
                <b-button variant="info" @click="copyItems()"><b-icon icon="clipboard" /> Copy Items to Clipboard</b-button>
                <b-table
                    :items="allItems"
                    :fields="fields">
                    <template v-for="(field, index) in editableFields" v-slot:[`cell(${field.key})`]="row">
                        <b-input v-model="row.item[field.key]" :key="index + '-input'" :disabled="!isEditable" />
                        <span
                        v-if="errors && errors['items'] && errors['items'][row.index] && errors['items'][row.index][field.key]"
                        class="error" :key="index + '-span'">
                        {{ errors['items'][row.index][field.key] }}
                        </span>
                    </template>
                    <template #cell(total)="row">
                        ${{ getItemTotal(row.item).toFixed(2) }}
                    </template>
                    <template #cell(actions)="row">
                        <b-button @click="onDeleteItem(row.index)" variant="danger" v-show="isEditable">
                            <b-icon icon="trash" />
                        </b-button>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
        <template #footer>
            <b-row>
                <b-col cols="2">
                    <span v-show="isEditable">
                        Tax Rule
                        <b-form-select v-model="applied_tax" :options="tax_options"  />
                    </span>
                </b-col>
                <b-col cols="4" />
                <b-col cols="2" style="text-align:right">Quantity:<strong> {{ itemQuantity.toFixed(2) }}</strong></b-col>
                <b-col cols="2">Sub-Total<br>
                    <span v-for="(tax, i) in applied_tax.individual_taxes" :key="i">
                        Estimated {{tax.name}} {{tax.percentage}}%<br>
                    </span>
                    <strong>Total ({{invoice.currency}})</strong>
                </b-col>
                <b-col cols="2" class="text-left">
                    <span>${{ subtotal.toFixed(2) }}<br></span>
                    <span v-for="(tax, i) in applied_tax.individual_taxes" :key="i">
                        ${{ get_tax_amount(tax).toFixed(2) }}<br>
                    </span>
                    <strong class="text-primary">${{grandTotal.toFixed(2)}}</strong>
                </b-col>
            </b-row>
        </template>
    </b-card>

    <b-form inline class="float-sm-right">

        <b-form-datepicker v-model="paidDate" v-if="isInvoiced && !isClosed" />
        <b-button title="This will mark the invoice as paid and indicate the date you specified in the field"
            @click="onPaid()"
            v-if="isInvoiced && !isClosed"
            class="mr-5"
            variant="success">
            Mark as paid
        </b-button>

        <b-button title="This will close, mark the invoice as a loss and not release items"
            @click="onLoss()"
            v-if="isInvoiced && !isClosed"
            class="mr-5"
            variant="warning">
            Mark as loss
        </b-button>

        <b-button title="This will close, cancel the invoice and release items"
            v-if="isInvoiced && !isClosed"
            @click="onCancel()"
            variant="danger">
            Cancel Invoice
        </b-button>

        <b-button title="This will delete the invoice and release items"
            v-if="isEditable && !isNew"
            @click="onDelete()"
            variant="danger">
            Delete Invoice
        </b-button>

        <b-button title="This will close invoice and generate the invoice PDF"
            v-if="isEditable & !isNew"
            @click="onGenerate()"
            variant="primary" >
            Generate Invoice
        </b-button>

        <b-button title="This will download the invoice PDF"
            v-if="!isEditable & invoice.status != 'Deleted'"
            @click="onGenerate()"
            variant="primary">
            Download PDF
        </b-button>

        <b-button title="This will save the invoice temporary and lock items"
            v-if="isEditable"
            @click="onSave()"
            variant="success">
            Save invoice
        </b-button>
    </b-form>

    <b-modal
        id="timesheet-modal"
        title="Timesheets"
        @show="getTimesheet()"
        v-model="showTimesheetModal"
        size="xl"
        scrollable
    >
        <b-table
            :items="timesheets"
            :fields="timesheetFields"
            show-empty
        >
            <template #empty>
                <h4>There are no open timesheets for this project!</h4>
            </template>
            <template #cell(selected)="row">
                <b-form-checkbox v-model="row.item.selected" />
            </template>
            <template #head(selected)>
                <b-form-checkbox @change="setTimesheetsSelected(!areAllTimesheetsSelected)">
                    {{ areAllTimesheetsSelected ? 'Un-select All' : 'Select All' }}
                </b-form-checkbox>
            </template>
        </b-table>
        <template #modal-footer>
            <b-row>
                <b-col cols="5">
                    <span>* Clicking 'Add Selected' will add the values to the invoice and save it</span>
                </b-col>
                <b-col>
                    <b-button
                        variant="primary"
                        size="sm"
                        @click="addSelectedTimesheets()"
                    >
                        Add Selected
                    </b-button>
                </b-col>
                <b-col>
                    <b-button
                        variant="primary"
                        size="sm"
                        @click="showTimesheetModal=false"
                    >
                        Close
                    </b-button>
                </b-col>
            </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getAllProjects } from '@/api/projects.api'

import { getOutstandingTimes, invoiceConsultingTime } from '@/api/consulting.api'
import { createInvoice, updateInvoice, getInvoice, generateInvoice, deleteInvoice, cancelInvoice, payInvoice, lossInvoice } from '@/api/invoices.api'
import { getClient } from '@/api/clients.api'
import { getSetting } from '@/api/settings.api'
import { listApplicableTaxes, getApplicableTax } from '@/api/taxes.api'

import InlineFormRow from '@/components/InlineFormRow'
import BMSCurrencySelector from '@/components/BMSCurrencySelector'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'

export default {
    mixins: [crudMixin, confirmationModal],
    data() {
        return {
            showTimesheetModal: false,
            id: this.$route.params.id,
            paidDate: null,
            invoice: {
                items: [],
                invoiced: this.formatDate(new Date())
            },
            clients: [],
            projects: {},
            timesheets: [],
            selected_client: null,
            defaultCurrency: null,
            timesheetItems: [],
            applicable_taxes: [],
            applied_tax: null,
            fields: [{
                key: 'description',
                label: 'Description',
                editable: true,
                thStyle: { width: "60%" },
            }, {
                key: 'quantity',
                label: 'Quantity',
                editable: true,
                thStyle: { width: "5%"}
            }, {
                key: 'cost',
                label: 'Price',
                editable: true,
                thStyle: { width: "15%" }
            }, {
                label: 'Total',
                key: 'total',
                thStyle: { width: "15%" }
            }, {
                label: 'Action',
                key: 'actions',
                thStyle: { width: "5%" }
            }],
            timesheetFields: [{
                key: 'selected'
            }, {
                key: 'date',
                label: 'Date'
            }, {
                key: 'comments',
                label: 'Description'
            }, {
                key: 'time',
                label: 'Hours'
            }]
        }
    },
    components: {
        InlineFormRow,
        BMSCurrencySelector
    },
    computed: {
        areAllTimesheetsSelected: function() {
            var toReturn = true
            this.timesheets.forEach(timesheet => {
                if(!timesheet.selected) {
                    toReturn = false
                    return
                }
            })
            return toReturn
        },
        allItems: function() {
            return this.invoice.items.concat(this.timesheetItems)
        },
        isNew: function() {
            return this.id != parseInt(this.id)
        },
        isInvoiced: function() {
            return this.invoice.status === 'INVOICED'
        },
        isEditable: function() {
            return !this.isInvoiced && !this.isClosed
        },
        isClosed: function() {
            return ['LOSS', 'CANCELLED', 'PAID'].includes(this.invoice.status)
        },
        itemQuantity: function() {
            var quantity = 0
            this.invoice.items.forEach(item => {
                if(!isNaN(item.quantity)) {
                    quantity += parseFloat(item.quantity)
                }
            })
            return quantity
        },
        grandTotal: function() {
            let grandtotal = this.subtotal
            this.applied_tax.individual_taxes.forEach(tax => grandtotal += this.get_tax_amount(tax))

            return grandtotal
        },
        subtotal: function() {
            var subtotal = 0
            this.allItems.forEach(item => {
                var cost = item.quantity*item.cost
                if(!isNaN(cost)) {
                    subtotal += cost
                }
            })
            return subtotal
        },
        editableFields: function() {
            return this.fields.filter(field => field.editable)
        },
        tax_options: function() {
            return this.applicable_taxes.map((tax) => {
                return {
                    value: tax,
                    text: `${tax.name}`
                }
            })
        },
        none_tax: function() {
            return {
                "name": "None",
                "individual_taxes": []
            }
        }
    },
    beforeMount() {
        this.applied_tax = this.none_tax
    },
    mounted() {
        this.getProjects();
        this.getApplicableTaxes()
        this.getDefaultApplicableTax()

        if (!this.isNew) {
            this.getInvoice();
        }
    },

    methods: {
        get_tax_amount(tax) {
            let toreturn = 0
            if (Object.hasOwn(tax, 'value')) {
                toreturn = parseFloat(tax.value)
            } else {
                toreturn = tax.percentage * this.subtotal / 100
            }
            return toreturn
        },
        changeClient(){
            if(this.invoice.project) {
                // If we change the client, we need to flush the project, and any timesheets
                this.$delete(this.invoice, 'project')
                this.timesheetItems.splice(0, this.timesheetItems.length)
            }
            this.call(
                getClient(this.selected_client), (res) => {
                    if (res.data.client.accounts_payable == null){
                        this.defaultCurrency = null
                    }else{
                        this.defaultCurrency =  res.data.client.accounts_payable.currency
                        this.invoice.currency = this.defaultCurrency
                    }

                    if(res.data.client.applicable_tax) {
                        this.applied_tax = res.data.client.applicable_tax
                    } else{
                        this.applied_tax = this.default_applicable_tax
                    }
                }
            )
        },
        setTimesheetsSelected: function(value) {
            this.timesheets.forEach(time => {
                time.selected = value
            })
        },
        getItemTotal: function(item) {
            var total = item.quantity*item.cost
            if(isNaN(total)) {
                total = 0
            }
            return total
        },
        addItem: function() {
            this.invoice.items.push({})
        },
        getProjects() {
            this.call(
                getAllProjects(),
                (res) => {
                    this.clients.splice(0)
                    res.data.projects.forEach(project => {
                        if (!this.clients.some(e => e.value === project.client.id)) {
                            var val = {
                                'value': project.client.id,
                                'text': project.client.company
                            }
                            if(!project.deactivated){
                                this.clients.push(val)
                            }
                            if(project.client.id in this.projects) {
                                this.projects[project.client.id].splice(0)
                            } else {
                                this.projects[project.client.id] = []
                            }
                        }
                        this.projects[project.client.id].push({
                            'value': project,
                            'text': `${project.name}`
                        })
                    })
                    this.clients.sort(function(a, b){return a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1});
                }
            )
        },
        getInvoice() {
            this.call(
                getInvoice(this.id),
                (res) => {
                    this.invoice = res.data.invoice;
                    this.invoice.taxes = this.decodeJSON(this.invoice.taxes, []);
                    if(this.invoice.taxes == null) {
                        this.invoice.taxes = []
                    }
                    this.selected_client = this.invoice.project.client.id
                    if (Object.hasOwn(this.invoice.taxes, 'id')) {
                        this.applied_tax = this.applicable_taxes.filter(tax => tax.id === this.invoice.taxes.id)[0]
                    } else {
                        this.applied_tax = {
                            "individual_taxes": this.invoice.taxes
                        }
                    }
                }
            )
            this.timesheetItems.splice(0, this.timesheetItems.length)
        },
        getApplicableTaxes() {
            this.applicable_taxes.splice(0)
            this.call(
                listApplicableTaxes(),
                (res) => {
                    this.applicable_taxes.push(...res.data.applicable_taxes)
                }
            )
        },
        getDefaultApplicableTax() {
            this.call(
                getSetting("default_applicable_tax"),
                (res) => {
                    let tax_number = res.data.setting.value
                    this.call(
                        getApplicableTax(tax_number),
                        (tax_res) => {
                            this.default_applicable_tax = tax_res.data.applicable_tax
                            if(this.applied_tax === this.none_tax){
                                this.applied_tax = this.default_applicable_tax
                            }
                        }
                    )
                }
            )
        },
        onDelete() {
            // confirmModal(message, okTitle, cancelTitle)
            this.confirmModal().then((result) => {
                if (result) {
                        this.deleteObject(
                        deleteInvoice(this.id),
                        () => {
                            this.$router.push('/invoices');
                        }
                    )
                }
            })
        },
        onCancel() {
            // confirmModal(message, okTitle, cancelTitle)
            this.confirmModal("Are you sure?", "YES", "NO").then((result) => {
                if (result){
                        this.call(
                        cancelInvoice(this.id),
                        () => {
                            this.$awn.success('Invoice has been cancelled successfully!');
                            this.$router.push('/invoices');
                        }
                    )
                }
            })
        },
        getTimesheet() {
            if(this.invoice.project.id) {
                this.call(
                    getOutstandingTimes({ project_id: this.invoice.project.id }),
                    (res) => {
                        // Empty array, VueJS style
                        this.timesheets.splice(0, this.timesheets.length)
                        res.data.times.forEach(time => {
                            time['selected'] = false
                            if(!this.timesheetItems.map(item => item.timesheet_id).includes(time.id)) {
                                this.timesheets.push(time)
                            }
                        })
                        this.timesheets.sort((a, b) => {
                            return new Date(a.date) - new Date(b.date);
                        })
                    }
                )
            }
        },
        formatDate(date) {
          // Split date by month, day and year
          var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

          // Padding with "0"
          if (month.length < 2)
            month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          // Return date yyyy-mm-dd
          return [year, month, day].join('-');
        },
        addSelectedTimesheets: function() {
            this.timesheets.filter(time => time.selected).forEach(timesheet => {
                this.timesheetItems.push({
                    "description": `[${timesheet.date}/${timesheet.user.username}] ${timesheet.comments ? timesheet.comments : ''}`,
                    "quantity": timesheet.time,
                    "cost": timesheet.rate,
                    "timesheet_id": timesheet.id
                })
            })
            this.showTimesheetModal = false
        },
        decodeJSON(val, empty) {
          try {
            return JSON.parse(val);
          } catch (e) {
            return empty;
          }
        },
        onDeleteItem(index) {
            var illusoryIndex = index - this.invoice.items.length
            if(illusoryIndex < 0) {
                this.invoice.items.splice(index, 1);
            } else {
                this.timesheetItems.splice(illusoryIndex, 1)
            }
        },
        onPaid() {
            this.call(
                payInvoice(this.id, this.paidDate),
                (res) => {
                    if(res.data == 'success') {
                        this.$awn.success('Invoice marked as paid successfully!');
                        this.getInvoice()
                    } else if ('errors' in res.data) {
                        this.$awn.alert(res.data.errors);
                    } else {
                        this.$awn.alert(res.data);
                    }
                }
            )
        },
        onLoss() {
            this.call(
                lossInvoice(this.id),
                () => {
                    this.$awn.success('Invoice marked as loss successfully!');
                    this.getInvoice()
                }
            )
        },
        onGenerate() {
            this.call(
                generateInvoice(this.id),
                (res) => {
                    this.$awn.success('Invoice generated successfully!');
                    window.open(res.data.url, "_blank");

                    // Refresh data
                    this.getInvoice();
                }
            )
        },
        async copyItems() {
            let order = ['description', 'quantity', 'cost']
            let items = [order].concat(this.invoice.items.map(it => {
                return [it[order[0]], it[order[1]], it[order[2]]]
            })).map(it => {
                return it.join(',')
            }).join('\n')
            try {
                await navigator.clipboard.writeText(items);
                this.$awn.success('Copied');
            } catch($e) {
                this.$awn.alert('Copy failed');
                console.log($e)
            }
        },
        onSave(showBanner=true) {
            this.invoice.taxes = JSON.stringify(this.applied_tax)

            var timesheets = JSON.parse(JSON.stringify(this.timesheetItems))

            var saveTimesheets = () => {
                this.call(
                    invoiceConsultingTime({
                        invoice_id: this.id,
                        times: timesheets.map(time => time.timesheet_id)
                    }),
                    () => {
                        this.getInvoice()
                    }
                )
            }
            if (this.isNew) {
                var apiCall = createInvoice(this.invoice)
                var callback = (res) => {
                    let findId = res.data.location.split('/');
                    this.id = findId[findId.length-1];
                    this.$router.push(`/invoices/${this.id}`);
                    this.getInvoice();
                    saveTimesheets()
                }
            } else {
                apiCall = updateInvoice(this.id, this.invoice)
                callback = () => {saveTimesheets()}
            }
            this.saveObject(
                apiCall,
                callback,
                showBanner
            )
        }
    }
}
</script>
<style scoped>
.invoice-bottom-buttons {
	text-align: right;
}
</style>

